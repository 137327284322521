@use "styles/variables";
@use "styles/typography";

.start {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  background: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
  background: url('../../../assets/backup-background.png');
  background-size: cover;
  background-repeat: no-repeat;

  img {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    left: 50%;
    transform: translateX(-55%);
    object-fit: cover;
  }

  button {
    padding: 10px;
  }
}

#video-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  pointer-events: none;
}

#video-bg::-webkit-media-controls-start-playback-button {
  display: none;
  opacity: 0;
}

.content-wrapper{
  display: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > :first-child {
    max-height: 50%;
    z-index:2;
    flex: 7;
  }
  
  & > * {
    flex: 3;
  }
}

.input-content {
  flex: 1;
  width: max-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;

  & > :first-child {
    padding-top: 50px;
    align-self: flex-start;
    font-weight: 400;
    line-height: 21.78px;
    size: 18px;
    font-family: variables.$font-regular;
    font-weight: 100;
    padding-bottom: 5px;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  & > :nth-child(2) {
    margin-top: 4px;
  }
  & > :first-child {
    margin-top: 20px;
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  
}
