@use "styles/variables";
@use "styles/typography";


.leaderboard-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  height: 100dvh;
  background: linear-gradient(rgba(0,0,0,0.38), rgba(0,0,0,1));
  align-items: center;
  flex-direction: column;
  padding: 30px 30px;
  border-radius: 5px;
  color: variables.$color-white;

  h1 {
    font-size: typography.$font-size-h3;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.leaderboard-content-wrapper {
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.leaderboard-close {
  position: absolute;
  top: 20px;
  left: 15px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  &::after {
    content: ' ';
    position: absolute;
    top: 25px;
    left: 0px;
    background-color: white;
    height: 2px;
    width: 15px;
    transform: rotate(45deg);
    border-radius: 5px;
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 15px;
    left: 0px;
    background-color: white;
    height: 2px;
    width: 15px;
    transform: rotate(-45deg);
    border-radius: 5px;
  }
}

.leaderboard-item {
  width: 100%;
  margin-top: 30px;
  font-family: variables.$font-bold;
  font-size: 16px;
  display: grid;
  text-align: left;
  align-items: center;
  grid-template-columns: 10% 80% 10%;

  .leaderboard-item--points {
    font-weight: 600;
  }
}


.leaderboard-item-green {
  width: 100%;
  margin-top: 30px;
  font-family: variables.$font-bold;
  font-size: 16px;
  display: grid;
  text-align: left;
  align-items: center;
  grid-template-columns: 10% 80% 10%;
  color: variables.$deloitte-green;

  .leaderboard-item--points {
    font-weight: 600;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  height: 15vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: variables.$color-black;

  & > :nth-child(2) {
    color: variables.$deloitte-green;
    padding-top: 3px;
  }
}
