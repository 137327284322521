@use "styles/variables";
@use "styles/typography";

.timer {
  color: variables.$color-white;
  font-size: typography.$font-size-body;
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;

  .timer--bar {
    width: 85%;
    height: 10px;
    background-color: #DFDFDF;
    align-self: center;
    border-radius: 5px;
    overflow: hidden;

    .timer--bar-fill {
      width: 2%;
      height: 10px;
      background-color: variables.$deloitte-green;
      border-radius: 5px;
      animation: timer forwards 20s linear;
    }
  }
}

#timer--count {
  min-width: 25px;
  font-family: variables.$font-bold;
}

@keyframes timer {
  from {
    width: 2%;
  }
  
  to {
    width: 100%;
  }
}
