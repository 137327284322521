.avatar {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  overflow:visible;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 120%;
    height: 120%;
    object-fit: cover;
    border-radius: 50%;
  }
}
