#game {
  position: relative;
  z-index: 1;
  overflow: hidden;
  @media screen and (max-width: 340px) {
    display: none;
  }
}

#game > canvas {
  position: absolute;
  margin-top: 0 !important;
  height: calc(100vh - 110px) !important;
  z-index: 4;
  bottom: 0;
  background: url('../../assets/backup-background.png');
  background-size: cover;
}

#video-bg {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  pointer-events: none;
}

#video-bg::-webkit-media-controls-start-playback-button {
  display: none;
  opacity: 0;
}