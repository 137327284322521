.selector-btn-down {
  display: flex;
  width: 250px;
  height: 50px;
  padding: 10px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  border: 3px solid #FFF;
  background-color: rgba(0,0,0,1);
  color: #FFF;

  :focus{
    outline: none;
  }

  :active{
    background-color: rgba(0,0,0,1);
  }
}

.selector-btn-up {
  display: flex;
  width: 250px;
  height: 50px;
  padding: 10px 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  border: 3px solid #FFF;
  border-bottom: 3px solid transparent;
  background-color: rgba(0,0,0,1);
  color: #FFF;

  :focus{
    outline: none;
  }

  :active{
    background-color: rgba(0,0,0,1);
  }
}

.selector {
  position: absolute;
  display: flex;
  max-height: 150px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0,0,0,1);
  color: #FFF;
  border-radius: 5px;
  padding: 0;
  border: 0 3px 3px 3px, solid, #FFF;
  overflow: scroll;
  z-index: 3;
  margin: 0;
}

.arrow-up {
  margin-left: -10px;
  justify-self: flex-end;
  border: solid #FFF;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  transform: rotate(-135deg);
  padding: 8px;
}

.arrow-down {
  margin-left: -10px;
  justify-self: flex-end;
  border: solid #FFF;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  transform: rotate(45deg);
  padding: 8px;
}

.arrow-wrapper-down{
  padding-right: 4px;
  padding-bottom: 3px;
}

.arrow-wrapper-up{
  padding-right: 4px;
  padding-top: 16px;
}

.option {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  text-decoration: none;
  width: 250px;
  color: #FFF;
  border-radius: 5px;
  padding: 2px;
  border: none;
  box-shadow: none;
  outline: none;
}
