.avatar-wrapper {
  object-fit: contain;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 30px;

  & > :first-child {
    width: 100px;
    height: 100px;
  }
}

.username-wrapper {
  margin: 30px 0 -30px 0;
}