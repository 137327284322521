@use "styles/typography";
@use "styles/variables";

.game-over-overlay {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: typography.$font-size-h2;
  background-color: variables.$color-black;
  color: variables.$color-white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 15;

  h1 {
    font-size: 128px;
  }

  .text {
    z-index: 2;
  }
}

.balloons-overlay {
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 978px;
  background-image: url('../../assets/deloitte-balloon-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  animation: balloons 3s linear forwards;
  transform: translateY(100%);
}

@keyframes balloons {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(-100%);
  }
}