@use "styles/variables";
@use "styles/typography";

.form--form-wrapper {
  position: relative;
  width: 100%;
  max-width: 240px;
  display: flex;
  align-items: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  border-radius: 5px;
  background: transparent;
  color: variables.$color-white;
  border: 3px solid variables.$color-white
  h1 {
    font-size: typography.$font-size-h3;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.form--input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: variables.$color-black;
  color: variables.$color-white;
  border: 3px solid variables.$color-white;
  width: fit-content;
  height: 50px;
  font-family: variables.$font-regular;
  font-size: 16px;
  padding: 5px 15px;
  margin: 10px;
  & > :focus {
    outline: none;
  }
}

.form--a {
  text-decoration: none;
  color: variables.$deloitte-green;
}

.form--policy-text {
  font-size: 10px;
}