@use 'styles/variables';

.game-background {
  position: relative;
  height: 100vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
  z-index: 1;
}

.game-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  height: 100%;
  background: url('../../../assets/background-loading.png') center/cover;
  z-index: 0;
  animation: background-slide linear 20s 1s forwards;
}

.balloon-wrapper {
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 2;
  padding-bottom: 0;
  margin-bottom: 0;
}

.balloon {
  height: 125%;
  width: 125%;
  transform: translate(-50%, -50%);
}

.text {
  position: fixed;
  top: 50%;
  left: 50%;
  font-size: 24px;
  font-weight: variables.$font-bold;
  transform: translate(-50%, -50%);
  color: #ffffff;
  z-index: 2;
  padding-top: 40px;
  margin-top: 0;
  white-space: nowrap;
}

@keyframes background-slide-out {
  from {
    transform: translate(50%, 50%)
  }
  to {
    transform: translate(-100%, -100%);
  }
}

@keyframes background-slide {
  from {
    transform: translate(0);
  }
  to {
    transform: translateX(-100%);
  }
}