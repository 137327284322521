@use 'styles/mixins';
@use 'styles/variables';
@use 'styles/typography';

.game-end-screen {
  position: relative;
  width: 100dvw;
  height: 100dvh;
  font-family: variables.$font-bold;
  color: variables.$color-white;
  display: flex;
  justify-content: center;
  z-index: 15;
  padding: 0 15px;
  text-align: center;

}

.score-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  font-size: 24px;
  & > :nth-child(2) {
    font-size: typography.$font-size-h1;
  }
  &, p{
    font-size: 24px;
    font-weight: bold;
  }

  &, .rank-wrapper {
    font-size: 15px;
    font-weight: normal;
  }
  &, .your-score-wrapper {
    font-size: 24px;
    font-weight: bold;
  }
}

.button-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  left: 0;
  position: fixed;
  bottom: 20px;
  & > :nth-child(2) {
    margin-top: 4px;
  }
  & > :first-child {
    margin-top: 20px;
  }
}

.background-wrapper {
  position: fixed;
  width: 100dvw;
  height: 100dvh;
  background: linear-gradient(rgba(0,0,0,0.38), rgba(0,0,0,1));
  bottom: 0;
  z-index: 0;
}

.game-end-screen--content {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: auto auto;
  z-index: 1;
}

.bestscore-wrapper {
  font-weight: bold;
  font-size: 100px;
}