@use "styles/variables";

.countdown-wrapper {
  background: radial-gradient(137% 50% at 50% 50%, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), #383063;;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade-out .6s linear 3.2s forwards;
  z-index: 5;

  .countdown {
    position: absolute;
    font-size: 150px;
    color: variables.$color-white;
    animation: fade-in .5s linear forwards;
  }
  
  .countdown-one {
    opacity: 0;
    animation: pulse 1.5s 3s forwards;
  }
  .countdown-two {
    opacity: 0;
    animation: pulse 1.5s 1.5s forwards;
  }
  .countdown-three {
    opacity: 1;
    animation: fade-in 1.5s forwards;
  }
  
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes pulse {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
