@use "styles/variables";
@use "styles/typography";

.button--contained {
  display: inline-flex;
  width: 135px;
  height: 60px;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 37px;
  border-bottom: 1px solid #9d71c1;
  background: radial-gradient(54.44% 111.67% at 52.17% 100%, #8B29D6 0%, rgba(94, 55, 124, 0.00) 100%), #1C1436;
  box-shadow: 2px 6px 0px -2px #372378, 0px 4px 25.9px 0px rgba(55, 18, 243, 0.45);
  font-weight: bold;
  font-size: 24px;
  &:hover {
    box-shadow: 2px 9px 0px -2px #372378, 0px 6px 25.9px 0px rgba(55, 18, 243, 0.65);
  }
}

.button--text {
  display: inline-block;
  white-space: nowrap;
  width: auto;
  height: 55px;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 27px;
  font-size: 24px;
  color: none;
  text-shadow: -1px -1px 0 #800080, 1px -1px 0 #800080, -1px 1px 0 #800080, 1px 1px 0 #800080;
  text-decoration: underline;
  background: transparent;
  box-shadow: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
  

  &:hover {
    text-decoration: underline;
  }
}

.button--outlined {
  display: flex;
  white-space: nowrap;
  width: 300px;
  height: 75px;
  padding: 10px 20px;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  justify-content: space-between;
  gap: 10px;
  border-radius: 5px;
  border: 3px solid variables.$color-white;
  background-color: variables.$color-black;
  color: variables.$color-white;
  font-weight: bold;
}