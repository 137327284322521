@use 'styles/mixins';
@use 'styles/variables';
@use 'styles/typography';

.intro {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fade-in 0.6s 1s linear forwards;
}

.intro-background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(0,0,0,0.38), rgba(0,0,0,1));
  bottom: 0;
  z-index: 0;
}

.content-wrapper {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: variables.$color-black;
  animation: fade-to-black 1s linear 0.1s forwards;
  opacity: 0;
  z-index: 1;
}

.contained-button-wrapper {
  position: fixed;
  bottom: 20px;
}

.intro-ballon {
  height: calc(100vw - 30px);
  max-height: 240px;
  margin-bottom: 40px;
}

.ballon-slide-out {
  animation: fly-away 0.8s ease-in forwards;
}

.intro-body {
  font-family: variables.$font-bold;
  font-weight: 300;
  font-size: typography.$font-size-body;
  margin: 45px 0;
  padding: 0 25px;
  text-align: center;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-to-black {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fly-away {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100vh);
  }
}