@use "styles/variables";
@use "styles/typography";

.counter-and-time {
  position: absolute;
  right: 0;
  z-index: 2;
  width: 100vw;
  height: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background-color: variables.$color-black;
}
