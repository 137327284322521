@use "styles/variables";
@use "styles/typography";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
  background-color: black;
  width: 100%;
  height: 100%;
}

body {
    font-family: variables.$font-regular;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-image: url('./assets/black-sparkling-background.gif');
}

button {
    border: none;
    background-color: variables.$color-purple;
    border-radius: 5px;
    font-family: variables.$font-bold, cursive;
    font-size: typography.$font-size-btn;
    font-weight: 500;
    color: variables.$color-white;
    padding: 12px 0;
}

#root {
  height: 100%;
}